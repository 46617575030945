import React from 'react'
import useLocalization from '../../useLocalization.js';


function Section7() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='section7bg'>
            <div className='container px-md-0 px-4'>
                <div className='row g-0 align-items-center'>
                    <div className='col-md-6'>
                        <div>
                            <h1 className='rezervoudhtiminheader'>{t('Rezervoni udhetimin')}<br />{t('tuaj')}</h1>
                        </div>
                    </div>
                    <div className='offset-md-1 col-md-5 mt-md-0 mt-4'>
                        <div>
                            <p className='paragraphrezervo'>
                            {t('Bashkohuni me komunitetin tonë të udhëtimeve për momente emocionuese, peisazhe mahnitëse dhe përvoja të paharrueshme. Frymëzohuni, gjeni oferta ekskluzive dhe ktheni ëndrrat tuaja të udhëtimit në realitet.')}
                                
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Section7