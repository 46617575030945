import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../components/Section5/styles.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HeaderSolo from './HeaderSolo';
import Footer from '../components/footer/Footer';
import axios from 'axios';
import Star from '../assets/star.svg'

function City() {
    const { countryName } = useParams();
    const [Items, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(`https://panel.stelatravel-rks.com/allcity/${countryName}`);
            setItems(response.data);
            console.log(Items);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div>
            <div className='headersolobg'>
                <div className='container'>
                    <HeaderSolo />
                </div>
            </div>
            <div className='container mb-5'>
                <div className='row g-0 text-center my-5 '>
                    <div className='col-12 text-center'>
                        <h1 className='udhetimettext text-center'>{countryName}</h1>
                    </div>
                </div>
                <div className='row g-md-4 align-items-center'>
                    {Items.map((slide, index) => (
                        <div className='col-md-4' key={index}>
                            <Link style={{ textDecoration: "none" }} to={`/hotelet/${slide.name}`}>
                                <div className='wrapperforcardscrs p-4' role='button'>
                                    <div>
                                        <img className='imageincard1sts' src={`${slide.images}`} alt="${slide.title}" />
                                    </div>
                                    <div className='row g-3 align-items-center mt-1'>
                                        <div className='col-md-12'>
                                            <div>
                                                <img src={Star} />
                                                <img src={Star} />
                                                <img src={Star} />
                                                <img src={Star} />
                                                <img src={Star} />
                                            </div>
                                        </div>
                                        <div className='col-md-12 col-12'>
                                            {/* <div className='d'>
                                                <span className='upsditenas'>Hotele Ne</span>
                                                <h2 className='citynames me-2'>{slide.name}</h2>
                                            </div> */}
                                            <div className='row g-3 align-items-center mt-1'>
                                                <div className='col-6'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='startlocationp me-2'>{slide.name}</span>
                                                    </div>
                                                </div>
                                                <div className='col-6 text-end'>
                                                    <button className='infoshtesbtn' style={{ color: 'black' }}>Cmime Speciale</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className='footerwrappersolo'>
                <Footer />
            </div>
        </div>
    );
}

export default City;