import React, { useState } from 'react';
import axios from 'axios';
import useLocalization from '../../useLocalization.js';
import Envelope from '../../assets/images/envelope.svg'
import Location from '../../assets/images/location.svg'
import Phone from '../../assets/images/phonefill.svg'

function Section11() {
    const { t, changeLanguage } = useLocalization();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://panel.stelatravel-rks.com/send-email', formData);
            alert('Email sent successfully!');
        } catch (error) {
            alert('Failed to send email.');
            console.error(error);
        }
        console.log(formData);
    };

    return (
        <div className='section11wrapper py-5'>
            <div className='container py-md-5 px-md-0 px-4'>
                <form onSubmit={handleSubmit}>
                    <div className='row g-md-3 g-3 align-items-center'>
                        <div className='col-md-12 mb-5'>
                            <div>
                                <h1 className='blackheadercontact'>{t('Na kontaktoni duke plotësuar formën e mëposhtme')}</h1>
                            </div>
                            <div className='mt-3'>
                                <p className='m-0 p-0'><span className='me-2'><img src={Envelope} /></span><strong>info@stelatravel-rks.com</strong></p>
                                <p className='m-0 p-0'><span className='me-2'><img src={Envelope} /></span><strong>reservation@stelatravel-rks.com</strong></p>

                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div>
                                <label className='fullwidth'>
                                    <span className='labelforiunput'> {t('Emri juaj i plote')}</span><br />
                                    <input type='text' name='fullName' value={formData.fullName} onChange={handleChange} required className='threeinputs mt-1 fullwidth' placeholder='Emri dhe Mbiemri' />
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div>
                                <label className='fullwidth'>
                                    <span className='labelforiunput'>E-mail addresa</span><br />
                                    <input type='email' name='email' value={formData.email} onChange={handleChange} className='threeinputs mt-1 fullwidth' placeholder='Email Adresa' />
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div>
                                <label className='fullwidth'>
                                    <span className='labelforiunput'>{t('Numri i telefonit')}</span><br />
                                    <input type='text' name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} className='threeinputs mt-1 fullwidth' placeholder='Numri telefonit' />
                                </label>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div>
                                <label className='fullwidth'>
                                    <span className='labelforiunput'>{t('Subjekti')}</span><br />
                                    <input type='text' name='subject' value={formData.subject} onChange={handleChange} className='threeinputs mt-1 fullwidth' placeholder='Subjekti' />
                                </label>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div>
                                <label className='fullwidth'>
                                    <span className='labelforiunput'> {t('Mesazhi')}</span><br />
                                    <textarea name='message' value={formData.message} onChange={handleChange} className='threeinputs fullwidth mt-1' rows={10}></textarea>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div>
                                <div>
                                    <label className='d-md-flex align-items-center'>
                                        <input type='checkbox' className='checkboxat' />
                                        <span className='labelforiunput ms-3'>{t('Unë i kam lexuar, dhe i pranoj Rregullat dhe kushtet dhe Politikën e Privatësisë.')}</span>
                                    </label>
                                </div>
                                <div className='mt-4'>
                                    <label className='d-md-flex align-items-center'>
                                        <input type='checkbox' className='checkboxat' />
                                        <span className='labelforiunput ms-3'>{t('Po, unë pranoj që të pranoj mesazha apo e-mail si oferta të ndryshme nga Stela Travel. Ju mund të largoni këtë të drejtë më vonë.')}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <div>
                                <button className='yellowboxes' style={{ border: 'none' }}>{t('Dergo mesazhin')}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Section11;
