import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import '../section2/CustomSwiper.css';
import Clock from '../../assets/images/clock.svg';
import Roma from '../../assets/images/roma.jpg';
import Milan from '../../assets/images/milan2.jpg';
import Amsterdam from '../../assets/images/amsterdam.jpg';
import Stamboll from '../../assets/images/stamboll.jpg';
import Budapest from '../../assets/images/BudapestBudapest.jpg';
import Viena from '../../assets/images/Viena.jpg';
import Venecia from '../../assets/images/Venecia.jpg';
import Zurich from '../../assets/images/Zurich.jpg';
import Barcelona from '../../assets/images/Barcelona.jpg';
import Paris from '../../assets/images/Paris.jpg';
import Ljubljana from '../../assets/images/Ljubljana.jpg';
import Munchen from '../../assets/images/Munchen.jpg';
import { EffectCards } from 'swiper/modules';
import { Link } from 'react-router-dom';
import useLocalization from '../../useLocalization.js';

const data = [
  { imageSrc: Milan, startLocation: 'Milano' },
  { imageSrc: Roma, startLocation: 'Roma' },
  { imageSrc: Venecia, startLocation: 'Venecia' },
  { imageSrc: Budapest, startLocation: 'Budapest' },
  { imageSrc: Viena, startLocation: 'Viena' },
  { imageSrc: Zurich, startLocation: 'Zurich' },
  { imageSrc: Amsterdam, startLocation: 'Amsterdam' },
  { imageSrc: Barcelona, startLocation: 'Barcelona' },
  { imageSrc: Stamboll, startLocation: 'Stamboll' },
  { imageSrc: Paris, startLocation: 'Paris' },
  { imageSrc: Ljubljana, startLocation: 'Ljubljana' },
  { imageSrc: Munchen, startLocation: 'Munchen' },
];

export default function App() {
  const { t, changeLanguage } = useLocalization();
  return (
    <>
      <div className='customSwiper container px-md-0 px-4'>
        <div className='row g-3 align-items-center mb-5'>
          <div className='col-md-6 text-start'>
            <h1 className='lastpieceheader'>{t('Destinacionet tona me te preferuara')}</h1>
          </div>
          <div className='col-md-6 text-end'>
            <p className='lastpiecepara m-0 p-0'>{t('Disa nga destinacionet me te kerkuara')}</p>
          </div>
        </div>
        <Swiper
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper"
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <Link to={`/hotelet/${item.startLocation}`} className='link-style'>
                <div className='wrapperforcardscrs p-4'>
                  <div>
                    <img className='imageincard1s' src={item.imageSrc} alt={`Image ${index}`} />
                  </div>
                  <div className='row g-3 align-items-center mt-1'>
                    <div className='col-6'>
                      <div className='d-flex align-items-center'>
                        <span className='startlocationp me-2'>{item.startLocation}</span>
                        <img className='imageinswiperoverwrite' src={Clock} alt="Clock Icon" />
                      </div>
                    </div>
                    <div className='col-6 text-end'>
                      <button className='infoshtesbtn' style={{ color: 'black' }}> {t('Cmime Speciale')}</button>
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
