import React from 'react'
import Swipersection5 from './Swipersection5.js'

function Section5() {
  return (
    <div className='container px-md-0 px-4 py-md-5'>
      <Swipersection5 />
    </div>
  )
}

export default Section5