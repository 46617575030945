import React, { useState } from 'react';
import Logo from '../assets/images/whitelogosvg.svg';
import Plane from '../assets/images/airplane.svg';
import Bus from '../assets/images/bus22.svg'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from '../i18n';
import useLocalization from '../useLocalization.js';


function HeaderSolo() {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const { t, changeLanguage } = useLocalization();

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    return (
        <div>
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
            <Link style={{ textDecoration: 'none' }} to={'/'}><a className="navbar-brand" href="#"><img src={Logo} alt="Logo" /></a></Link>
                <button className="navbar-toggler" type="button" onClick={toggleNavbar} aria-expanded={isNavbarOpen ? "true" : "false"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="white" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                    </svg>
                </button>
                <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0 py-4">
                        <li className="nav-item me-4">
                            <Link style={{ textDecoration: 'none' }} to={'/'}><a className="nav-link" href="/">{t('Ballina')}</a></Link>
                        </li>
                        <li className="nav-item me-4">
                            <a className="nav-link" href="#section2">{t('Destinacionet')}</a>
                        </li>
                        <li className="nav-item dropdown me-4">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {t('Sherbimet')}
                                <i className="bi bi-caret-down-fill"></i>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link style={{ textDecoration: 'none' }} to={'/biletaavioni'}><a className="dropdown-item" href="#">✈️ {t('Bileta Avioni')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/biletautobusi'}><a className="dropdown-item" href="#">🚍 {t('Bileta Autobusi')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/biletagrupi'}><a className="dropdown-item" href="#">🚌 {t('Udhetime Grupore')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/biletatrageti'}><a className="dropdown-item" href="#">🚢 {t('Bileta Trageti')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/arazhmaneverore'}><a className="dropdown-item" href="#">🌊 {t('Arazhmane Verore')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/arazhmanedimrore'}><a className="dropdown-item" href="#">🏂 {t('Arazhmane Dimerore')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/hotelet'}><a className="dropdown-item" href="#">🏨 {t('Rezervime hotelesh ne te gjithe boten')}</a></Link></li>
                                <li><Link style={{ textDecoration: 'none' }} to={'/sigurimi'}><a className="dropdown-item" href="#">💚 {t('Sigurim shëndetsor')}</a></Link></li>
                            </ul>
                        </li>
                        <li className="nav-item me-4">
                        <a className="nav-link" href="#footer">{t('Rreth Nesh')}</a>
                        </li>
                        <li className="nav-item me-4">
                            <a className="nav-link" href="#section8"> <img src={Plane} alt="Plane" />{t('Pushime & Vikende')} <img src={Bus} alt="Bus" /></a>
                        </li>
                        <li className="nav-item me-4">
                        <Link style={{ textDecoration: 'none' }} to={'/info'}><a className="nav-link" href="#footer">{t('Kontakti')}</a></Link>
                        </li>
                    </ul>
                    <div class="d-flex">
                    <button  className='languagebtn1 me-3 borderoright' onClick={() => changeLanguage('en')}><img className='flagimages' src='https://cdn.britannica.com/00/6200-004-42B7690E/Flag-Albania.jpg' /></button>
                            <button  className='languagebtn2 ' onClick={() => changeLanguage('sq')}><img className='flagimages' src='https://cdn.britannica.com/25/4825-004-F1975B92/Flag-United-Kingdom.jpg' /></button>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    );
}

export default HeaderSolo;
