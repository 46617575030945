import React from 'react'
import Logo from '../../assets/images/logo.svg'
import whitelogo from '../../assets/images/whitelogosvg.svg'
import Phone from '../../assets/images/phone.svg'
import Location from '../../assets/images/location.svg'
import Email from '../../assets/images/email.svg'
import facebook from '../../assets/images/facebook.svg'
import instagram from '../../assets/images/instagram.svg'
import tiktok from '../../assets/images/tiktok.svg'
import useLocalization from '../../useLocalization.js';

function Footer() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='footerbg pt-md-5'>
            <div className='container py-5 px-md-0 px-4'>
                <div className='row g-md-4 g-3'>
                    <div className='col-md-6'>
                        <div>
                            <div>
                                <img src={whitelogo} />
                            </div>
                            <div className='mt-3 mb-3'>
                            <a href='tel:+38349833700' className='yellowboxes2 contactnumber'><img src={Phone} /> +383 49 833 700</a>
                            </div>
                            <div className='mt-2'>
                                <p style={{fontSize: '18px'}} className='m-0 p-0 footerp'><img src={Location} className='ms-2 me-2' />137 Bill Clinton</p>
                            </div>
                            <div className='mt-2'>
                                <p style={{fontSize: '18px'}} className='m-0 p-0 footerp'><img src={Location} className='ms-2 me-2' />
                                Pristina 10000</p>
                            </div>
                            <div className='mt-2'>
                                <p style={{fontSize: '18px'}} className='m-0 p-0 footerp'><img src={Email} className='ms-2 me-2' />info@stelatravel-rks.com</p>
                            </div>
                            <div className='mt-2'>
                                <p style={{fontSize: '18px'}} className='m-0 p-0 footerp ms-2'>Your best travel home...</p>
                            </div>
                            <div className='mt-3'>
                                  <div className='row g-3'>
                                        <div className='col-auto'><a href='https://www.facebook.com/stelatravel.rks'><img className='wrapperforsocialicons' src={facebook} /></a></div>
                                        <div className='col-auto'><a href='https://www.instagram.com/stelatravel.rks'><img className='wrapperforsocialicons' src={instagram} /></a></div>
                                        <div className='col-auto'><a href='https://www.tiktok.com/@udhetopushoks'><img className='wrapperforsocialicons' src={tiktok} /></a></div>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 prpfooter'>
                        <div className='row g-md-5 g-3'>
                            <div className='col-md-3'>
                                   <ul>
                                       <li className='mb-2'><span className='listtitle'>{t('Lidhjet')}</span></li>
                                       <li className='mb-2'><span className='listitems'>{t('Rreth Nesh')}</span></li>
                                       <li className='mb-2'><span className='listitems'>{t('Destinacionet')}</span></li>
                                       <li className='mb-2'><span className='listitems'>{t('Kontakti')}</span></li>
                                       <li className='mb-2'><span className='listitems'>{t('Bllogu')}</span></li>
                                   </ul>
                            </div>
                            <div className='col-md-3'>
                                   <ul>
                                       <li className='mb-2'><span className='listtitle'>{t('Kontakti')}</span></li>
                                       <li className='mb-2'><span className='listitems'>Facebook</span></li>
                                       <li className='mb-2'><span className='listitems'>Instagram</span></li>
                                       <li className='mb-2'><span className='listitems'>TikTok</span></li>
                                   </ul>
                            </div>
                            <div className='col-md-4'>
                                   <ul>
                                       <li className='mb-2'><span className='listtitle'>{t('Pushime')}</span></li>
                                       <li className='mb-2'><span className='listitems'>{t('Pushime speciale')}</span></li>
                                       <li className='mb-2'><span className='listitems'> {t('Pushime egzotike')}</span></li>
                                       <li className='mb-2'><span className='listitems'>City Break</span></li>
                                   </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='dividerfooter' style={{ borderColor: "#FFFFFF66" }} />
            <div className='row g-0 align-items-center pb-3'>
                 <div className='col-md-12 text-center'>
                       <span className='allrightsreservedtext'>Copyright © 2024 Stela Travel {t('Të gjitha të drejtat e rezervuara')}</span>
                 </div>
            </div>
        </div>
    )
}

export default Footer