import React from 'react'
import PlaneWhite from '../assets/images/airplanewhite.svg'
import useLocalization from '../useLocalization.js';

function Section1secondcontent() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='Ftopn2 pb-md-0 pb-3'>
            <div className='wrappergreyalpha p-2'>
                <div className='row g-3 align-items-center '>
                    <div className='col-md-auto'>
                        <div className='yellowboxes mobiletextcenter'>
                            {t('E RE!')}
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div>
                            <p className='p-0 m-0 alphaboxfirsttxt mobiletextcenter'>{t('Bileta aeroplani duke filluar nga 13.99€')}</p>
                            <p className='alphaboxsecondtxt mt-2 mb-0 me-0 ms-0 p-0 mobiletextcenter'>{t('Fluturime nga Prishtina drejt destinacioneve europiane & anasjelltas')}</p>
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <a href='#footer' style={{textDecoration:'none'}}><div className='yellowboxes mobiletextcenter'>
                            <img className='me-2' src={PlaneWhite} />
                            {t('Rezervo Tani')}
                        </div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1secondcontent