import React, { useState, useEffect } from 'react';
import HeaderSolo from '../../View/HeaderSolo'
import Tiran from '../../assets/images/tiran.jpg';
import stamboll from '../../assets/images/stamboll.jpg';
import Selanik from '../../assets/images/selanik2.jpg';
import croatia from '../../assets/images/croatia.jpg';
import montenegro from '../../assets/images/montenegro.jpg';
import Blackplane from '../../assets/images/blackplane.svg';
import Rightline from '../../assets/images/rightline.svg';
import { Link } from 'react-router-dom';
import bigPhone from '../../assets/images/bigPhone.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../Section5/styles.css';
import Footer from '../footer/Footer';

const swiperData = [
    {
        slides: [
            { image: Selanik, title: 'Greqi' },
            { image: stamboll, title: 'Turqi' },
            { image: Tiran, title: 'Shqiperi' },
            { image: croatia, title: 'Koraci' },
            { image: montenegro, title: 'Maltzi' },
        ],
    },
];

function Avion() {
    const [slidesPerView, setSlidesPerView] = useState(3);
    const [spaceBetween, setSpaceBetween] = useState(60);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 776) {
                setSlidesPerView(1);
                setSpaceBetween(28);
            } else {
                setSlidesPerView(3);
                setSpaceBetween(60);
            }
        };

        handleResize(); // Call once to set initial values

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div>
            <div className='' style={{ backgroundColor: '#0095DA' }}>
                <HeaderSolo />
            </div>
            <div class='section1avionbg'>
                <div class="content">
                    {/* <h1 className='udhetimettext text-center' style={{ color: 'white' }}>Bileta Avioni</h1> */}
                </div>
            </div>
            <div className='my-5 px-md-5 px-3'>
                <div className='row g-0 text-center mt-5 '>
                    <div className='col-12 text-center'>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <div className='contactforminsideResort mt-md-5'>
                    <div className='my-md-5'>
                        <h1 style={{ color: '#0095DA' }}>Bileta avioni per gjitha destinacionet me cmimet me te mira ne treg...<br />
                            per info dhe rezervime:<br />
                            <span style={{ color: '#0095DA' }}>Kontakt:</span> <a href='#'>+383 49 833 700</a></h1>
                    </div>
                    <div className='pt-5'>
                        <div className=''>
                            <form className='row g-md-4'>
                                <div className='col-12'>
                                    <h4 className='text2header'>Na Kontakto Permes Emailit</h4>
                                </div>
                                <div className='col-4'>
                                    <label className='labeliiii' for="fullname">Full Name:</label>
                                    <input className='inputincontactresrt' type='text' placeholder='John Smith' />
                                </div>
                                <div className='col-4'>
                                    <label className='labeliiii' for="email">Email:</label>
                                    <input className='inputincontactresrt' type='email' placeholder='John Smith' />
                                </div>
                                <div className='col-4'>
                                    <label className='labeliiii' for="phonenumber">Phone Number:</label>
                                    <input className='inputincontactresrt' type='text' placeholder='+123456789' />
                                </div>
                                <div className=''>
                                    <label className='labeliiii' for="message">Message:</label>
                                    <textarea className='inputincontactresrt' rows={5}>
                                    </textarea>
                                </div>
                                <div>
                                    <button className='dergobtn'>Dergo</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr className='my-5' />
                    <div className='viaphone mb-5'>
                        <div className='viaphonewrapper'>
                            <form className='row g-md-4'>
                                <div className='col-12 mb-4'>
                                    <h4 className='text2header'>Lidhu direkt me një nga agjentët tanë</h4>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row g-md-2 align-items-center'>
                                        <div className='col-md-5'>
                                            <hr />
                                        </div>
                                        <div className='col-2'>
                                            <a href='#' className='d-flex'>
                                                <img src={bigPhone} className='mx-auto' />
                                            </a>
                                        </div>
                                        <div className='col-md-5'>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className='text-center mt-5'>
                                        <a href='#' className='callbutonssasd'><p className='callparag mb-4'>+38349833700</p></a>
                                        <a href='#' className='callbutonssasd'><p className='callparag'>+38349833700</p></a>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Footer />
            </div>

        </div>
    )
}

export default Avion