import { useTranslation } from 'react-i18next';
import i18n from './i18n';

const useLocalization = () => {
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return {
    t,
    changeLanguage,
  };
};

export default useLocalization;
