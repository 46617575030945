import React from 'react';
import plane from '../../assets/images/section3plane.svg';
import clock from '../../assets/images/section3clock.svg';
import phone from '../../assets/images/section3phone.svg';
import beach from '../../assets/images/section3beach.svg';
import useLocalization from '../../useLocalization.js';

function Section3() {
  // Array of objects representing data for each section
  const sectionsData = [
    {
      image: plane,
      text: 'Zgjidhni planin perfekt të udhëtimit'
    },
    {
      image: phone,
      text: 'Rezervoni planin tuaj të udhëtimit të ëndrrave'
    },
    {
      image: clock,
      text: '24/7 duke mbështetur planin tuaj të udhëtimit'
    },
    {
      image: beach,
      text: 'Udhëzues të shkëlqyeshëm për udhëtimin tuaj'
    }
  ];

  const { t, changeLanguage } = useLocalization();
  return (
    <div className='bluebg'>
      <div className='container py-5 px-md-0 px-4'>
        <div className='row g-4 align-items-center'>
          {sectionsData.map((section, index) => (
            <div key={index} className='col-md-3 text-center'>
              <div className='p-md-4 p-3'>
                <div>
                  <img src={section.image} alt={`Section ${index}`} />
                </div>
                <div className='mt-3'>
                  <p className='bluebgtext'>{section.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section3;
