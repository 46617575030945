
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../View/HeaderSolo.js';
import Section12 from '../section12/Section12.js';
import Footer from '../footer/Footer.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Office from './Office.js'
import Locations from './Locations.js'
import Section11 from '../Section11/Section11.js';

function Infor() {
       
       return (
              <div className="App">
                     <div className=''style={{backgroundColor:'#0095DA'}}>
                            <div className='container px-md-0 px-4' style={{ height: "100%" }}>
                                   <div >
                                          <Header />
                                   </div>
                            </div>
                     </div>
                     <div id='office' className='section2 my-5'>
                            <Office />
                     </div>
                     <div id='office' className='section2 my-5'>
                            <Locations />
                     </div>
                     <div>
                            <Section11 />
                     </div>
                     <div id='footer' className='footer'>
                            <Footer />
                     </div>
              </div>
       );
}

export default Infor;
