import React from 'react'
import star from '../../assets/images/start.svg'
import useLocalization from '../../useLocalization.js';

function Section12() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='section12wrapperbg mt-5'>
            <div className='container py-md-5 px-md-0 px-4'>
                <div className='py-5'>
                    <div className='row g-4 align-items-center py-md-5'>
                        <div className='col-12 text-center mb-5'>
                            <h1 className='blackheadercontact text-center'>
                            Udhëto & Pusho <br /> your best travel home
                            </h1>
                        </div>
                        <div className='col-md-4'>
                            <div className='wrapperforreviews'>
                                <div>
                                    <p className='paragraphrevies'>
                                        {t('Kjo është zgjedhja më e mirë e agjencisë së udhëtimit që kam bërë ndonjëherë, për shkak të shërbimit të saj të lartë dhe çmimit të volitshëm.')}
                                    </p>
                                </div>
                                <div className='pt-4'>
                                    <p className='m-0 p-0 strongpreviews'>Dren Ismaili</p>
                                    <div>
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='wrapperforreviews'>
                                <div>
                                    <p className='paragraphrevies'>
                                    {t('Agjensioni Turistik Stela më ka befasuar me oferta të pakrahasueshme për shumë destinacione të bukura shqiptare.')}
                                    </p>
                                </div>
                                <div className='pt-4'>
                                    <p className='m-0 p-0 strongpreviews'>Lavdim Berisha</p>
                                    <div>
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='wrapperforreviews'>
                                <div>
                                    <p className='paragraphrevies'>
                                    {t('Me stafin e shkëlqyer që na përkëdhel ne klientët, me oferta të shkëlqyera për familjet u bë udhëtimi ynë i ëndrrave.')}
                                    </p>
                                </div>
                                <div className='pt-4'>
                                    <p className='m-0 p-0 strongpreviews'>Laurenta Haziri</p>
                                    <div>
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section12