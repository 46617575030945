import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

const checkAdminRole = () => {
  const token = Cookies.get('jwt_token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
      return userRole === '1';
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return false; 
    }
  }
  return false;
};

const ProtectedRoute = ({ element: Component }) => {
  const isAdmin = checkAdminRole();

  if (isAdmin) {
    return <Component />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
