import React from 'react'
import useLocalization from '../../useLocalization.js';
import facebook from '../../assets/images/facebookblack.svg'
import instagram from '../../assets/images/instagramblack.svg'
import Phone from '../../assets/images/phonefill.svg'
import Location from '../../assets/images/location.svg'
import Email from '../../assets/images/email.svg'
import '../Info/location.css'

function Locations() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div>
            <div className='container py-5 px-md-0 px-4'>
                <div className='row g-md-5 g-3'>
                    <div className='col-12 col-lg-8'>
                        <div className='google-maps'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2934.3010163746594!2d21.153008376614004!3d42.65497611641779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM5JzE3LjkiTiAyMcKwMDknMjAuMSJF!5e0!3m2!1sen!2s!4v1709904302685!5m2!1sen!2s" title="External Content" width="800" height="600"></iframe>
                        </div>
                    </div>
                    <div className='col-12 col-lg-4'>
                            <p style={{fontSize: '25px'}}>Info :</p>
                            <p style={{fontSize: '20px'}}><img src={Location} className='ms-2 me-2' />137 Bill Clinton</p>
                            <p style={{fontSize: '20px'}}><img src={Location} className='ms-2 me-2' />Pristina 10000</p>
                            <p style={{fontSize: '20px'}}><img src={Phone} className='ms-2 me-2' />Tel : 049833700</p>
                            <p style={{fontSize: '20px'}} className='ms-3'>Mobile or Whatsapp</p>
                            <p style={{fontSize: '20px'}}><img src={Phone} className='ms-2 me-2' />+38349833700</p>
                            <p style={{fontSize: '20px'}}><img src={Email} className='ms-2 me-2' />info@stelatravel-rks.com</p>
                            <p style={{fontSize: '20px'}} className='mt-3'>Folllow Us</p>
                            <div className='mt-3'>
                                  <div className='row g-3'>
                                        <div className='col-auto'><a href='https://www.facebook.com/stelatravel.rks'><img className='wrapperforsocialicons' src={facebook} /></a></div>
                                        <div className='col-auto'><a href='https://www.instagram.com/stelatravel.rks'><img className='wrapperforsocialicons' src={instagram} /></a></div>
                                  </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Locations