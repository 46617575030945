import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import './FormComponent.css';

const FormComponent = () => {

  const [Items, setItems] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://panel.stelatravel-rks.com/allitems');
      setItems(response.data);
      console.log(Items)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [formData, setFormData] = useState({
    images: '',
    title: '',
    price: '',
    place: '',
    date: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request using Axios
      const response = await axios.post('https://panel.stelatravel-rks.com/uploaddata', formData);
      console.log('Server response:', response.data);
      // Reset form fields
      setFormData({
        images: '',
        title: '',
        price: '',
        country: '',
        city: '',
        date: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const handleSubmitCity = async (e) => {
    e.preventDefault();
    try {
      // Make POST request using Axios
      const response = await axios.post('https://panel.stelatravel-rks.com/cityadd', formData);
      console.log('Server response:', response.data);
      // Reset form fields
      setFormData({
        images: '',
        name: '',
        country: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const handleSubmit2 = async (e) => {
    e.preventDefault();
    try {
      // Make POST request using Axios
      const response = await axios.post('https://panel.stelatravel-rks.com/posts', formData);
      console.log('Server response:', response.data);
      // Reset form fields
      setFormData({
        images1: '',
        images2: '',
        images3: '',
        images4: '',
        images5: '',
        images6: '',
        images7: '',
        images8: '',
        images9: '',
        title: '',
        price: '',
        place: '',
        country: '',
        date: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
    <form onSubmit={handleSubmit} className="form-container">
      <h2 className="form-heading">Add New Item</h2>
      <div className="form-group">
        <label htmlFor="images">Images:</label>
        <input
          type="text"
          id="images"
          name="images"
          value={formData.images}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter title"
        />
      </div>
      <div className="form-group">
        <label htmlFor="price">Price:</label>
        <input
          type="text"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter price"
        />
      </div>
      <div className="form-group">
        <label htmlFor="place">Country:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter country"
        />
      </div>
      <div className="form-group">
        <label htmlFor="place">City:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter city"
        />
      </div>
      <div className="form-group">
        <label htmlFor="date">Date:</label>
        <input
          type="date"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-submit">Submit</button>
      </form>

      <form onSubmit={handleSubmitCity} className="form-container">
      <h2 className="form-heading">Add New City</h2>
      <div className="form-group">
        <label htmlFor="images">Images:</label>
        <input
          type="text"
          id="images"
          name="images"
          value={formData.images}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="title">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="price"> Country:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter country"
        />
      </div>
        <button type="submit" className="btn btn-submit">Submit</button>
      </form>
      











      <form onSubmit={handleSubmit2} className="form-container">
      <h2 className="form-heading">Add Ofert</h2>
      <div className="form-group">
        <label htmlFor="images">Images1:</label>
        <input
          type="text"
          id="images1"
          name="images1"
          value={formData.images1}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images2:</label>
        <input
          type="text"
          id="images2"
          name="images2"
          value={formData.images2}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images3:</label>
        <input
          type="text"
          id="images3"
          name="images3"
          value={formData.images3}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images4:</label>
        <input
          type="text"
          id="images4"
          name="images4"
          value={formData.images4}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images5:</label>
        <input
          type="text"
          id="images5"
          name="images5"
          value={formData.images5}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images6:</label>
        <input
          type="text"
          id="images6"
          name="images6"
          value={formData.images6}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images7:</label>
        <input
          type="text"
          id="images7"
          name="images7"
          value={formData.images7}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images8:</label>
        <input
          type="text"
          id="images8"
          name="images8"
          value={formData.images8}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="images">Images9:</label>
        <input
          type="text"
          id="images9"
          name="images9"
          value={formData.images9}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter image URL"
        />
      </div>
      <div className="form-group">
        <label htmlFor="title">Title:</label>
        {/* <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter title"
        /> */}
        <select
  id="title"
  name="title"
  value={formData.title}
  onChange={handleChange}
  className="form-control"
  placeholder="Enter title"
>
  {Object.entries(Items).map(([key, value]) => (
    <option key={key}>{value.title}</option>
  ))}
</select>
      </div>
      <div className="form-group">
        <label htmlFor="price">Price:</label>
        <input
          type="text"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter price"
        />
      </div>
      <div className="form-group">
        <label htmlFor="place">Place:</label>
        <input
          type="text"
          id="place"
          name="place"
          value={formData.place}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter place"
        />
      </div>
      <div className="form-group">
        <label htmlFor="place">country:</label>
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="form-control"
          placeholder="Enter country"
        />
      </div>
      <div className="form-group">
        <label htmlFor="date">Date:</label>
        <input
          type="date"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-submit">Submit</button>
      </form>
      </>
    
    );
  };
  
  export default FormComponent;
