import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Register from './Register';
import Login from './Login';
import AdminDashboard from './Admin/Dashboard.js';
import ProtectedRoute from './ProtectedRoute';
import Hotelet from './View/Hotelet.js';
import Resorts from './View/Resorts.js';
import City from './View/City.js'
import Avion from './components/Pushimet/Avion.js';
import Autobus from './components/Pushimet/Autobus.js';
import Traget from './components/Pushimet/Traget.js';
import Group from './components/Pushimet/Group.js';
import Family from './components/Pushimet/Family.js';
import FamilyWinter from './components/Pushimet/FamilyWinter.js';
import Infor from './components/Info/Info.js';
import Hotels from './components/Pushimet/Hotels.js';
import Sigurimi from './components/Pushimet/Sigurimi.js';

const Routesapp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/hotelet/:countryName" element={<Hotelet />} />
        <Route path="/resorts/:resortName" element={<Resorts />} />
        <Route path="/citys/:countryName" element={<City />} />
        <Route path="/biletaavioni" element={<Avion />} />
        <Route path="/biletautobusi" element={<Autobus />} />
        <Route path="/biletatrageti" element={<Traget />} />
        <Route path="/biletagrupi" element={<Group />} />
        <Route path="/arazhmaneverore" element={<Family />} />
        <Route path="/arazhmanedimrore" element={<FamilyWinter />} />
        <Route path="/hotelet" element={<Hotels />} />
        <Route path="/sigurimi" element={<Sigurimi />} />
        <Route path="/info" element={<Infor />} />
        <Route
          path="/admindashboard"
          element={<ProtectedRoute element={AdminDashboard} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Routesapp;
