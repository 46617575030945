import React from 'react'
import useLocalization from '../../useLocalization.js';

function Section4() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='section4bg'>
            <div className='container px-md-0 px-4'>
                <div className='d-flex justify-content-center pt-5'>
                    <h1 className='bigTextHeader textwhite pt-md-5'>{t('Destinacionet')}</h1>
                </div>
            </div>
        </div>
    )
}

export default Section4