import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../Section5/styles.css';
import Tiran from '../../assets/images/tiran.jpg';
import stamboll from '../../assets/images/stamboll.jpg';
import Selanik from '../../assets/images/selanik2.jpg';
import croatia from '../../assets/images/croatia.jpg';
import montenegro from '../../assets/images/montenegro.jpg';
import Blackplane from '../../assets/images/blackplane.svg';
import Rightline from '../../assets/images/rightline.svg';
import { Link } from 'react-router-dom';
import useLocalization from '../../useLocalization.js';

const swiperData = [
  {
    slides: [
      { image: 'https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910733/ob0zi6cccdpv6btlbonv.jpg', title: 'Greqi' },
      { image: 'https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910734/rsj6hd1ivgoetkshzeyh.jpg', title: 'Turqi' },
      { image: 'https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910723/gvya9rfzwqtbkuhbmvbk.jpg', title: 'Shqiperi' },
      { image: 'https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910728/iznxap6n5kci1ydz18zq.jpg', title: 'Koraci' },
      { image: 'https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910731/zddva4t3bi1vjjgrhpcm.jpg', title: 'Maltzi' },
    ],
  },
];

export default function Section9Swiper() {
  const { t, changeLanguage } = useLocalization();
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [spaceBetween, setSpaceBetween] = useState(60);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 776) {
        setSlidesPerView(1);
        setSpaceBetween(28);
      } else {
        setSlidesPerView(3);
        setSpaceBetween(60);
      }
    };

    handleResize(); // Call once to set initial values

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className='row g-0 text-center mt-5 '>
        <div className='col-12 text-center'>
          <h1 className='udhetimettext text-center'>{t('Oferta Vikendi')}</h1>
        </div>
      </div>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        className="mySwiper p-4 px-5"
      >
        {swiperData[0].slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Link to={`/citys/${slide.title}`} className="link-style">
              <div className='wrapperforcardscrs p-4' role='button'>
                <div>
                  <img className='imageincard1s' src={slide.image} alt={slide.title} />
                </div>
                <div className='row g-3 align-items-center mt-1'>
                  <div className='col-4'>
                    <div className='d-flex align-items-center'>
                      <span className='startlocationp me-2'>Prishtine </span>
                      <img className='imageinswiperoverwrite' src={Blackplane} alt="Blackplane" />
                    </div>
                  </div>
                  <div className='offset-1 col-7'>
                    <img className='rightlinetorotate' src={Rightline} alt="Rightline" />
                  </div>
                  <div className='col-md-7 col-6'>
                    <img className='rightlinetorotate rotate' src={Rightline} alt="Rightline" />
                  </div>
                  <div className=' col-md-4 col-6'>
                    <div className='d-flex align-items-center'>
                      <span className='startlocationp me-2' >{slide.title}</span>
                      <img className='imageinswiperoverwrite rotate' src={Blackplane} alt="Blackplane" />
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <button className='infoshtesbtn'>{t('More Informations')}</button>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
