import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Section1content from './components/Section1content';
import Section1secondcontent from './components/Section1secondcontent';
import CustomSwiper from './components/section2/CustomSwiper.js';
import Section3 from './components/Section3/Section3.js';
import Section4 from './components/Section4/Section4.js';
import Section5 from './components/Section5/Section5.js';
import About from './components/About/About.js';
import Section7 from './components/section7/Section7.js';
import Section8 from './components/Section8/Section8.js';
import Section9Swiper from './components/section9/Section9Swiper.js';
import Section10Swiper from './components/section10/Section10Swiper.js';
import Section11 from './components/Section11/Section11.js';
import Section12 from './components/section12/Section12.js';
import Footer from './components/footer/Footer.js';
import Greece from './components/Locations/Greece.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';

function App() {
    return (
        <div className="App">
                    <div className='section1'>
                        <div className='container px-md-0 px-4' style={{ height: "100%" }}>
                            <Header />
                            <div className='sectionconentnt' style={{ height: "70%" }}>
                                <Section1content />
                            </div>
                            <Section1secondcontent />
                        </div>
                    </div>
                    <div id='section2' className='section2 my-5'>
                        <CustomSwiper />
                    </div>
                    <div id='section3' className='section3'>
                        <Section3 />
                    </div>
                    <div id='section4' className='section4'>
                        <Section4 />
                    </div>
                    <div id='section5' className='section5'>
                        <Section5 />
                    </div>
                    <div id='section6' className='aboutus'>
                        <About />
                    </div>
                    <div id='section7' className='section7'>
                        <Section7 />
                    </div>
                    <div id='section8' className='section8'>
                        <Section8 />
                    </div>
                    <div id='section9' className='section9'>
                        <div className='container px-md-0 px-4'>
                            <Section9Swiper />
                        </div>
                    </div>
                    {/* <div id='section10' className='section10'>
                        <div className='container px-md-0 px-4'>
                            <Section10Swiper />
                        </div>
                    </div> */}
                    <div id='section11' className='section11'>
                        <Section11 />
                    </div>
                    <div id='section12' className='section12'>
                        <Section12 />
                    </div>
                    <div id='footer' className='footer'>
                        <Footer />
                    </div>
        </div>
    );
}

export default App;
