import React from 'react'
import useLocalization from '../../useLocalization.js';

function About() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='cap30' id='rrethnesh'>
            <div className='container px-md-0 px-4'>
                <div className='py-5'>
                    <div>
                        <h1 className='rrethhead'>
                            {t('Rreth Nesh')}
                        </h1>
                    </div>
                    <div className='py-md-5'>
                        <p className='paragraphtaboutus'>{t('Kush jemi ne')}</p>
                    </div>
                    <div>
                        <p className='secparagraphtaboutus'>
                            {t("Agjencioni Turistik 'Stela Travel' është emër i ri i cili është regjistruar në Republiken e Kosovës në Prishtinë. Por me traditë shum vjeqare në fushen e turizmit dhe hotelerisë tani me emër të ri e me traditë të vjetër e me një staf te specializuar ne industrine e turizmit dhe hotelerise.")}
                        </p>
                        <p className='secparagraphtaboutus'>
                            {t("Udhëtimi është e vetmja gjë që bleni ju bën më të pasur'. Ne betohemi plotësisht për këtë dhe besojmë në përmbushjen e ëndrrave të udhëtimit që ju bëjnë të pasuroheni pa ndryshim gjatë ditës. Ne kemi shitur përvoja të bukura për vite me rradhë përmes paketave tona më të dizajnuara të udhëtimeve dhe pushimeve e shërbimeve të tjera thelbësore të udhëtimit. Ne frymëzojmë klientët tanë të jetojnë një jetë të pasur, plot përvoja të paharrueshme të udhëtimit. Misioni ynë nuk është thjesht të jemi një agjensi udhëtimi e cila ju ndihmon të rezervoni një udhëtim apo pushim ose festë ; ne duam të jemi partneri juaj i udhëtimit i cili do të shkonte një milje ekstra për t'ju ndihmuar të zgjidhni një destinacion ideal dhe të mësoni më shumë rreth vendit që keni ëndërruar të vizitoni.  Meqenëse pushimet kanë të bëjnë më shumë me zgjedhjet dhe interesat personale, ne ju sjellim edhe ju paketa të personalizueshme turne, pushime, udhëtime dhe fluturime në të gjitha destinacionet e botës. Ne ju japim prerogativën për të zgjedhur dhe zgjedhur gjithçka që përputhet me interesin tuaj. Pavarësisht sa përvoja të udhëtimit ne shesim çdo ditë, ne sigurohemi që ju të jeni protagonisti i historisë tuaj të udhëtimit. Destinacioni me ne nuk është kurr nje vend por nje menyre e re për të eksploruar gjëra apo përvoja të ndryshme.")}

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About