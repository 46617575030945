import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import { useParams, Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import shqiperi from '../../assets/images/p1.jpg';
import montenegro from '../../assets/images/p2.jpg';
import turkey from '../../assets/images/p3.jpg';
import maldive from '../../assets/images/maldives.jpg';
import thai from '../../assets/images/thai.jpg';
import dubai from '../../assets/images/dubai.jpg';
import santorini from '../../assets/images/santorini.jpg';
import egypt from '../../assets/images/egypt.jpg';
import './styles.css';
import useLocalization from '../../useLocalization.js';


export default function Swipersection5() {
    const [slidesPerView, setSlidesPerView] = useState(3);
    const [spaceBetween, setSpaceBetween] = useState(60);
    const { countryName } = useParams();
    const { t, changeLanguage } = useLocalization();


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 776) {
                setSlidesPerView(1);
                setSpaceBetween(28);
            } else {
                setSlidesPerView(3);
                setSpaceBetween(60);
            }
        };

        handleResize(); // Call once to set initial values

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
            <div className='row text-center'>
                <div className='col-12'>
                    <h1>{t("Cmimet me te mira ne treg per pushime me 'Stela Travel'")}</h1>
                </div>
            </div>
            <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper p-4 px-5"
            >
                <SwiperSlide>
                    <Link to={`/citys/Greqi`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910733/dunalnthikgtlv09q3bp.jpg' alt="Santorini" />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>GREQI</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Shqiperi`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910732/ln1iuer3atm5jeervrvp.jpg' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>SHQIPERI</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Maltzi`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910733/tnyqe09apyd56zvtnyg6.png' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>MALI I ZI</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Turqi`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910732/g8t6qe0tsirmco1hz1lv.jpg' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>TURQI</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Maldive`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910730/ofrcik6m8e9qemcxl1is.jpg' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>MALDIVE</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Egjipt`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910729/eamyqmaympmtl0a81ray.jpg' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>EGJIPT</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Tajland`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910723/j7ljm67aenwfllrjm7ri.jpg' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>TAJLAND</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/citys/Dubai`} className="link-style">
                        <div className='wrapperforcardscrs p-4'>
                            <div>
                                <img className='imageincard1s' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910729/nab1tscov9d1bdrcaglz.jpg' />
                            </div>
                            <div className='mt-4'>
                                <p className='cardtitle m-0 p-0'>DUBAI</p>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
