import React from 'react'
import Cap1 from '../../assets/images/cap1.png'
import Cap2 from '../../assets/images/cap2.png'
import Cap3 from '../../assets/images/traget.jpg'
import useLocalization from '../../useLocalization.js';

function Section8() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div className='py-md-5'>
            <div className='container py-5 px-md-0 px-4'>
                <div className='row g-md-5 g-3 align-items-center'>
                    <div className='col-md-12 text-center mb-5'>
                        <div className='text-center'>
                            <h1 className='lightblueheader text-center'>{t('Udhetimet')}</h1>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='capiamgewrapper'>
                            <a href='#section9'><img className='imagescap' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910727/xvwenc9k3ucbaiqzxtzz.png' /></a>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='capiamgewrapper'>
                            <a href='#section10'><img className='imagescap' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910727/nocsowvzlwp73chmsn5k.png' /></a>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='capiamgewrapper'>
                            <a href='#section10'><img className='imagescap' src='https://res.cloudinary.com/ds4q3qncb/image/upload/v1709910723/xxmvhlmsnpgykuwxyvke.jpg' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section8