import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../components/Section5/styles.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HeaderSolo from './HeaderSolo';
import Footer from '../components/footer/Footer';
import axios from 'axios';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import star from '../assets/star.svg'
import bigPhone from '../assets/images/bigPhone.svg'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const Resorts = () => {
    const { resortName } = useParams();
    const [Items, setItems] = useState([]);
    const [ThumbsSwiper, setThumbsSwiper] = useState(null);
    const [country, setCountry] = useState();
    const [resorts, setResorts] = useState([]);
    useEffect( () => {
        fetchData();
 
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://panel.stelatravel-rks.com/allofertaplace/${resortName}`);
            console.log(response.data);
            setItems(response.data);
            setCountry(response.data[0]['country'])
            const response2 = await axios.get(`https://panel.stelatravel-rks.com/allitemscountry/${response.data[0]['country']}`);
            setResorts(response2.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const updateSwiper = (swiper) => {
        if (swiper) {
            setThumbsSwiper(swiper);
        }
    };
    return (
        <div>
            <div style={{ backgroundColor: '#0095DA' }}>
                <HeaderSolo />
            </div>
            <div className='container'>
                <div className='row g-md-5'>
                    <div className='col-md-8'>
                        <div className='row g-0'>
                            <div className='col-12 pt-5 mb-4'>
                                  <h4><strong>Resort In {country}</strong></h4>
                            </div>
                            <div className='col-md-12 p-2'>
                                <div className="pb-5 mb-5">
                                    <Swiper
                                        style={{
                                            '--swiper-navigation-color': '#fff',
                                            '--swiper-pagination-color': '#fff',
                                        }}
                                        loop={true}
                                        spaceBetween={10}
                                        navigation={true}
                                        thumbs={{ swiper: ThumbsSwiper && !ThumbsSwiper.destroyed ? ThumbsSwiper : null }}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper2"
                                    >

                                        {Items.map((item, index) => (
                                            <div key={index}>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images1}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images2}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images3}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images4}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images5}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images6}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images7}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images8}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='bigcarouselimgs' src={`${item.images9}`} />
                                                </SwiperSlide>
                                            </div>
                                        ))}

                                    </Swiper>
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        loop={true}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper mt-3"
                                    >
                                        {Items.map((item, index) => (
                                            <div key={index[2]}>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images1}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images2}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images3}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images4}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images5}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images6}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images7}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images8}`} />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <img className='smallcarouselimgs' src={`${item.images9}`} />
                                                </SwiperSlide>
                                            </div>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        {Items.map((item, index) => (
                            <div className='row g-md-5'>
                                <div className='col-12'>
                                    <h1 key={index}>{item.title}</h1>
                                </div>
                                <div className='col-6'>
                                    <div className='yjet'>
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                        <img src={star} />
                                    </div>
                                    <span className='firstdesc'>Duke filluar nga: </span><span><strong>{item.price}$</strong> Per Person</span>
                                </div>

                                <div className='description'>
                                    <p className='firstdesc'>
                                        Start your Eastern Europe trip from Berlin – one of the most attractive European cities. Head out to Warsaw – the capital of Poland, where you’ll be able to take a guided tour through the city’s places of interests and museums. Enjoy all Prague has to offer. Opt to visit the famous Charles Bridge, the clock tower in the main square or a traditional Czech beer hall with serious helpings of meat and potatoes. Opt to have lunch in one of the many pretty restaurants offering fantastic local cuisine, sitting on a terrace overlooking this fairytale medieval town.
                                    </p>
                                    <p className='seconddesc'>
                                        End your Eastern Europe tour by visiting two most renowned European cities – Vienna and Budapest. Wander the elegant streets a little more, take in some of Europe’s most distinguished art galleries or just settle yourself on a café terrace, order up a slice of mouthwatering Viennese cake and watch the world go by over coffee.
                                    </p>
                                </div>
                            </div>
                        ))}
                        <hr className='my-5' />
                        <div className='contactforminsideResort'>
                            <div>
                                <div className=''>
                                    <form className='row g-md-4'>
                                        <div className='col-12'>
                                            <h4 className='text2header'>Na Kontakto Permes Emailit</h4>
                                        </div>
                                        <div className='col-4'>
                                            <label className='labeliiii' for="fullname">Full Name:</label>
                                            <input className='inputincontactresrt' type='text' placeholder='John Smith' />
                                        </div>
                                        <div className='col-4'>
                                            <label className='labeliiii' for="email">Email:</label>
                                            <input className='inputincontactresrt' type='email' placeholder='John Smith' />
                                        </div>
                                        <div className='col-4'>
                                            <label className='labeliiii' for="phonenumber">Phone Number:</label>
                                            <input className='inputincontactresrt' type='text' placeholder='+123456789' />
                                        </div>
                                        <div className=''>
                                            <label className='labeliiii' for="message">Message:</label>
                                            <textarea className='inputincontactresrt' rows={5}>
                                            </textarea>
                                        </div>
                                        <div>
                                            <button className='dergobtn'>Dergo</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <hr className='my-5' />
                            <div className='viaphone mb-5'>
                                <div className='viaphonewrapper'>
                                    <form className='row g-md-4'>
                                        <div className='col-12 mb-4'>
                                            <h4 className='text2header'>Lidhu direkt me një nga agjentët tanë</h4>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='row g-md-2 align-items-center'>
                                                <div className='col-md-5'>
                                                    <hr />
                                                </div>
                                                <div className='col-auto'>
                                                    <a href='#'>
                                                        <img src={bigPhone} />
                                                    </a>
                                                </div>
                                                <div className='col-md-5'>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className='text-center mt-5'>
                                                <a href='#' className='callbutonssasd'><p className='callparag mb-4'>+38349833700</p></a>
                                                <a href='#' className='callbutonssasd'><p className='callparag'>+38349833700</p></a>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 pt-5'>
                        <div className='p-2'>
                            <div className='mb-5'>
                                <h4><strong>Other Resorts in {country}</strong></h4>
                            </div>
                            {resorts.map((resort, index) => (
                                <div key={index} className='fixedelemsatresort mb-5'>
                                    <img className='imagiforsugestions' src={`${resort.images}`} />
                                    <p><strong>{resort.title}</strong></p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Resorts;
