import React from 'react'
import useLocalization from '../useLocalization.js';

function Section1content() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div>
            <div className='mt-5'>
                <div className='text-center'>
                    <div>
                        <h1 className='bigTextHeader'>{t('Pushime verore ne destinacionet me te kerkuara')}</h1>
                    </div>
                    <div>
                        <p className='SmallerTextParagraph'> {t('Shijoni sherbimet tona per udhetime dhe pushime ne destinacionet me te kerkuara...')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1content