import React from 'react'
import Office1 from '../../assets/images/office1.jpeg'
import Office2 from '../../assets/images/office2.jpeg'
import useLocalization from '../../useLocalization.js';

function Office() {
    const { t, changeLanguage } = useLocalization();
    return (
        <div>
            <div className='container py-5 px-md-0 px-4'>
                <div className='row g-md-5 g-3 align-items-center'>
                    <div className='col-md-12 text-center mb-5'>
                        <div className='text-center'>
                            <h1 className='lightblueheader text-center'>{t('Kontakti')}</h1>
                        </div>
                    </div>
                   

                    <div className='col-md-6'>
                        <div className='capiamgewrapper'>
                            <a href='#section9'><img className='imagescap2' src={Office1} /></a>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='capiamgewrapper'>
                            <a href='#section10'><img className='imagescap2' src={Office2} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Office